<template>
    <div>
        <headerOrder></headerOrder>  
        
        <div class="contentMain">
            <div class="headerBack">
                
                </div>
          <div class="main">
                
            <div>
                <div class="mainLeft">
                    <div class="mainbox" style="padding-top:30px;margin-top:0">
                        <div class="box">
                            <div class="title title1">
                            
                              <img src="./../../assets/images/jiantoucai.png" alt="" srcset="" style="width:20px;height:24px;margin-right:5px">  {{hxInfo.startSeaport}} -- {{hxInfo.targetSeaport}} <i style="margin-left:20px" v-if="userInfo.bizStatus != 'checked'">当前账户未通过审核，请到<i class="link" @click="gouser">个人中心</i>完善准确信息</i>
                            </div>
                        </div>
                        <div style="padding-top:20px">
                            <el-form :rules="rules" :model="ruleForm" ref="ruleForm">
                                <!-- <el-form-item label="活动时间" required> -->
                                <el-form-item label="公司名称"  label-width="100px" prop="weCompanyName">
                                    <el-input v-model="ruleForm.weCompanyName" placeholder="请输入公司名称" :disabled="userInfo.bizStatus != 'checked'" style="width:260px"></el-input>
                                </el-form-item>
                                 <el-form-item label="联系人"  label-width="100px"  prop="weContacts">
                                    <el-input v-model="ruleForm.weContacts" placeholder="请输入联系人" :disabled="userInfo.bizStatus != 'checked'" style="width:260px"></el-input>
                                </el-form-item>
                                 <el-form-item label="联系电话"  label-width="100px" prop="wePhone">
                                    <el-input v-model="ruleForm.wePhone" placeholder="请输入联系电话" :disabled="userInfo.bizStatus != 'checked'" style="width:260px"></el-input>
                                </el-form-item>
                                  <el-form-item label="预估货重"  prop="weProductWeight" label-width="100px">
                                    <el-input v-model="ruleForm.weProductWeight" placeholder="请输入预估货重" :disabled="userInfo.bizStatus != 'checked'" style="width:260px"></el-input>
                                </el-form-item>
                                 <el-form-item label="预定柜量"  prop="weNum" label-width="100px">
                                    <el-input type="number" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="ruleForm.weNum" @input="jisuancabinetNum" placeholder="请输入预定柜量" :disabled="userInfo.bizStatus != 'checked'" style="width:260px"></el-input>
                                </el-form-item>
                                 <el-form-item label="品名"  label-width="100px" prop="weProductName">
                                    <el-input v-model="ruleForm.weProductName" :disabled="userInfo.bizStatus != 'checked'" placeholder="请输入品名" style="width:260px"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div> 

                <div class="mainbox" style="margin-top:20px">
                    <div class="box">
                        <div class="title title1">
                            <img src="./../../assets/images/jiantoucai.png" alt="" srcset="" style="width:20px;height:24px;margin-right:5px">上传附件 
                        </div>
                    </div>
                    <div class="flex upload">
                        <el-upload
                            class="upload-demo"
                            drag
                            :limit="1"
                            action="https://test.dosbooking.com:8700/system/uploadImg"
                            :on-success="handleSuccess"
                            multiple>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            </el-upload>
                            <div class="fileListt">
                                <div v-for="(item,index) in fileList" :key="item.id" class="filebox">
                                    <div style=" margin-left: 34px;">
                                       
   
                                        {{item.name}}
                                    </div>
                                    <div style="margin-right: 10px;width:24px;height:24px" class="closefile" @click="deltfile(index)">
                                        <img src="./C.png" alt="" srcset="" style="width:24px;height:24px">
                                    </div>
                                </div>
                            </div>
                    </div>
                </div> 
                <div class="mainbox" style="margin-top:20px">
                    <div class="box">
                        <div class="title title1">
                           <img src="./../../assets/images/jiantoucai.png" alt="" srcset="" style="width:20px;height:24px;margin-right:5px"> 费用
                        </div>
                    </div>
                    <div class="flex xiangxing">
                        <div class="smallTitle">
                            费用名称
                        </div>
                        <div class="smallTitle">
                            总金额
                        </div>
                    </div>
                    <div  class="flex xiangxing" style="margin-top:10px" v-show="ruleForm.weNum != ''">
                       <div>
                           {{hxInfo.startSeaport}}-{{hxInfo.targetSeaport}}的航线货柜 X{{ruleForm.weNum}}
                       </div>
                       <div >
                          {{hxInfo.cabinetAmount * ruleForm.weNum}}(USD)
                       </div>
                    </div>
                    
                    <div  class="flex xiangxing" style="margin-top:10px"  v-if="hxInfo.cnyTotal == 0 || hxInfo.usdTotal == 0">
                       <div v-if="hxInfo.cnyTotal == 0 || hxInfo.usdTotal == 0">
                          附加费  <el-popover
                                    placement="right"
                                    width="400"
                                    trigger="click">
                                    <el-table :data="hxInfo.surchargeAmountDetail">
                                        <el-table-column width="150" property="feeName"  label="费用名称"></el-table-column>
                                        <el-table-column width="200" property="fee" label="费用金额">
                                            <template slot-scope="scope">
                                                <div style="width:200px">
                                                    {{scope.row.totalfee}}({{scope.row.feeType}}) <span v-show="scope.row.totalFlag">货柜:X({{ruleForm.cabinetNum}})</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <!-- <el-table-column width="300" property="address" label="地址"></el-table-column> -->
                                    </el-table>
                            <span class="fjiaf" slot="reference">
                                详情
                            </span>
                        
                         </el-popover>
                       </div>
                       <div >
                             <span> {{cnyTotal}}(CNY) </span> <span> +{{usdTotal}} (USD)</span>
                       </div>
                       
                    </div>
                    <!-- <div  class="flex xiangxing" style="margin-top:10px">
                       <div>
                          服务费 X{{params.cabinetNum}}
                       </div>
                       <div>
                          {{hxInfo.serviceFee * params.cabinetNum}} (USD)
                       </div>
                       
                    </div> -->
                   <div  class="flex xiangxing" style="margin-top:10px" v-show="hxInfo.depositAmount">
                       <div>
                           <!-- -->
                          履约保证金 X{{ruleForm.weNum}} (订单结束后返还)
                       </div>
                       <div>
                          {{hxInfo.depositAmount * ruleForm.weNum}} (USD)
                       </div>
                       
                    </div>
                    
                </div> 
                
            </div>
                <div class="dcxie">
                    <el-checkbox v-model="checked"></el-checkbox>请认真阅读并同意<i class="cursor" @click="dialogVisible2 = true">《订舱协议》</i>
                </div>
                <div class="tijiao" @click="subOrder('ruleForm')"  >
                    提交
                </div>
            </div>
            <div class="mainRight">
                <!-- <div class="rightbox">
                    <div class="righttitle">
                       <div>
                           托运人
                       </div>
                    </div>
                    <div class="shuju">
                        <div style="margin-top:28px">
                            <img src="./no.png" alt="" srcset="">
                        </div>
                        <div style="margin-top:6px">
                            暂无数据
                        </div>
                    </div>
                </div> -->
                <div class="rightbox">
                    <div class="rightTitle">
                        <div>
                            订单详情
                        </div>
                    </div>
                    <div class="rightText">
                        <div>
                             起运港：{{hxInfo.startSeaport}}
                        </div>
                        <div>
                            中转港：<span v-show="hxInfo.targetSeaport == hxInfo.targetSeaport">直航</span> <span v-show="hxInfo.targetSeaport != hxInfo.targetSeaport">{{hxInfo.targetSeaport}}中转</span>
                        </div>
                        <div>
                             目的港：{{hxInfo.targetSeaport}}
                        </div>
                         <!-- <div>
                             zh：{{hxInfo.targetSeaport}}
                        </div> -->
                        <div>
                             船司：{{hxInfo.shipCompanyName}}
                        </div>
                        <div>
                             柜型：{{hxInfo.cabinetShape}}
                        </div>
                        
                        <div>
                             截文件时间：{{hxInfo.closeFileTime.substr(0, 10)}}
                        </div>
                        <div>
                             截放行时间：{{hxInfo.closePassTime.substr(0, 10)}}
                        </div>
                        <div>
                             开船时间：{{hxInfo.sailTime.substr(0, 10)}}
                        </div>
                        <div>
                             航运备注：{{hxInfo.remark}}
                        </div>
                        <div>
                             提单方式：{{hxInfo.orderMode}}
                        </div>
                        <div>
                             付款方式：{{hxInfo.payMode}}
                        </div>
                        <div>
                             放货方式：{{hxInfo.releaseMode}}
                        </div>
                        <div>
                             运输条款：{{hxInfo.transTerms}}
                        </div>
                         <div>
                             柜子价格：{{hxInfo.cabinetAmount}}(USD)
                        </div>
                        {{hxInfo.cnyTotal}}{{hxInfo.usdTotal}}
                         <div>
                             附加费： <span v-if="!hxInfo.cnyTotal|| !hxInfo.usdTotal">时报时销</span> <span v-if="hxInfo.cnyTotal || hxInfo.usdTotal">
                                 {{hxInfo.cnyTotal}}(CNY) + {{hxInfo.usdTotal}}(USD)
                             </span>
                        </div>
                    </div>
                </div>
                <!-- <div class="rightbox">
                    <div class="righttitle">
                       驳船信息
                    </div>
                    <div class="boc">
                       <div>
                           <div>
                                驳船起运港
                           </div>
                           <div class="strong">
                               --
                           </div>
                       </div>
                       <div >
                           <div>
                                驳船码头
                           </div>
                           <div class="strong">
                               --
                           </div>
                           
                       </div>
                    </div>
                    <div class="boc">
                       <div>
                           <div>
                                驳船截文件日期
                           </div>
                           <div class="strong">
                               --
                           </div>
                       </div>
                       <div >
                           <div>
                                驳船开船日期
                           </div>
                           <div class="strong">
                              --
                           </div>
                           
                       </div>
                    </div>
                    <div class="boc">
                       <div>
                           <div>
                                中转方式
                           </div>
                           <div class="strong">
                               --
                           </div>
                       </div>
                    </div>
                </div>
                <div class="rightbox" style="margin-top:20px">
                    <div class="righttitle">
                        大船信息
                    </div>
                    <div class="boc">
                       <div>
                           <div>
                                大船起运港
                           </div>
                           <div class="strong">
                               {{hxInfo.startSeaport}}
                           </div>
                       </div>
                       <div >
                           <div>
                                大船中转港
                           </div>
                           <div class="strong">
                               {{hxInfo.changeSeaport}}
                           </div>
                           
                       </div>
                    </div>
                    <div class="boc">
                       <div>
                           <div>
                                大船目的港
                           </div>
                           <div class="strong">
                               {{hxInfo.targetSeaport}}
                           </div>
                       </div>
                       <div >
                           <div>
                                船司
                           </div>
                           <div class="strong">
                               {{hxInfo.shipCompanyEName}}
                           </div>
                           
                       </div>
                    </div>
                    <div class="boc">
                       <div>
                           <div>
                                服务编码
                           </div>
                           <div class="strong">
                               --
                           </div>
                       </div>
                       <div style="margin-left:125px">
                           <div>
                                大船起运港码头
                           </div>
                           <div class="strong">
                               --
                           </div>
                       </div>
                    </div>
                </div> -->
            </div>
        </div>

        </div>
        <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <div class="dialogtitle">
            <div>
                请认真核对该舱位的特殊要求
            </div>
            <div style="margin-top:20px">
                特殊要求：{{hxInfo.remark}}
            </div>
        </div>
        <span slot="footer" class="dialog-footer1">
            <el-button type="primary" @click="submitFreightOrder" :disabled="userInfo.bizStatus != 'checked'" >确认并提交</el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
        </span>
        </el-dialog>
        <el-dialog  title="订舱协议"
        :visible.sync="dialogVisible2"
        width="60%"
        :before-close="handleClose1">
            <div class="xiyititle">
                海运出口货物订舱合同
            </div>
            <div class="">
                <div>编号：</div>
                <div>甲方：(托运人)</div>
                <div>地址：</div>
                <div>经办人：</div>
                <div>联系电话：</div>
                <div>银行账户：</div>
                <div>乙方：(承运人)</div>
                <div>地址：广东省深圳市罗湖区深南东路2010-1号五楼东</div>
               <div> 经办人：深圳市海豚寻舱科技有限公司</div>
               <div> 联系电话：0755-25100671</div>
                <div>银行账户：3301040160018693138</div>

               <div> 甲乙双方经过友好协商，就办理甲方货物海运出口订舱事宜达成如下合同：</div>
               <div> 第一条委托事项</div>
               <div> 乙方同意接受甲方的委托办理甲方有关货物的海运出口订舱事宜(具体货物品名、数量、装卸港等以甲方最终的订舱单为准)，具体流程如下：</div>
               <div> 1、甲方在乙方平台填写订舱信息，预定舱位；</div>
               <div> 2、支付舱位履约保证金（若供应方要求支付履约保证金）；</div>
               <div> 3、完成支付并提交订单后，甲方将根据乙方在预定舱信息中的电话联系跟进，确定订舱单，协助完成预定舱位相关事宜；</div>
                <div>4、物流抵达目的地后，形成详细货物运输账单（结合实际运输情况可能较原平台展示的价格有部分偏差；主要包括港口特殊情况产生的额外费用需由甲方自行承担），甲方需在出账后15日内向乙方进行费用结算。</div>
                <div>第二条 甲、乙双方的权利与义务</div>
                <div>1、甲方应在订舱时如实地上报/填写联系人姓名、联系方式、货物的品名、数量、质量、装卸港口等内容。对于上述信息错误而导致的损失或者责任，由甲方自行承担。</div>
                <div>2、甲方提交预定舱单后，乙方应及时与甲方取得联系，因甲方联系方式有误，导致乙方无法取得联系的后果由甲方自行承担。</div>
                <div>3、乙方通过平台或者其他方式向甲方提供船期预报以及截止接单日期。上述船期预报不构成双方对船舶驶离装货港和抵达卸货港具体的时间约定，仅作为甲乙双方办理海运订舱事宜的参考。</div>
                <div>4、甲方应根据船期预报，在截止接单日之前确认最终订舱订单。对于超过截止接单日甲方要求加载的货物，乙方积极配合甲方的要求，但是对于确实无法加载的货物，乙方不承担任何责任。</div>
                <div>5、乙方在收到甲方最终确认的订舱单后，应积极协助甲方办理订舱、配载等手续。除甲方能证明乙方在订舱、配载上有过错外，乙方不承担任何责任。如果货物未能如期配载，乙方应及时将有关情况通知甲方。</div>
                <div>6、甲方的货物包装应适合海洋运输或者货物的特殊属性，包装外面应注明搬运、储存、防护等标识。甲方如果对货物的储存、防护或者出口运输有特殊要求，应在订舱时注明。在甲方货物包装明显不适应海洋运输或者货物特殊属性时，乙方对于货物在运输过程中的灭失或损坏不负责任。</div>
                <div>7、对于货物本身以及货物包装不当造成的第三方的损失，仍应由甲方承担。</div>
                <div>8、乙方收到甲方最终确认的订舱单后，甲方又要求变更订舱单所列事项的，应在货物装船7天前联系乙方并向乙方出具书面更改单，注明日期并加盖甲方印章。因变更订舱事项所引起的各项费用，由甲方全部承担。</div>
                <div>9、船舶在开航前，甲方要求解除订舱的，根据《海商法》的有关规定以及本合同约定处理。</div>
                <div>第三条 费用结算</div>
                <div>乙方完成定配舱的，甲方应按照乙方要求及时与乙方结算相关费用，结算内容如下：</div>
                <div>（1）对于预付运费条款下的出口货物，乙方在配舱回单上应标注运费基价以及服务费用。甲方应于货物发运前，按照运费单价及货物数量向乙方指定的账户支付运费以及服务费用。如标注的运费单价以及服务费用有误，甲方应在船舶开航前向乙方提出更正，否则视为接受乙方所标注的运费基价以及服务费用。</div>
                <div>（2）对于其他运费条款下的出口货物，乙方在配舱回单上应标注运费基价和运费、服务费用支付的最后期限。甲方应于上述期限届满前，按照运费单价及货物数量向乙方指定的账户支付运费、服务费用。如标注的运费单价、服务费用有误，甲方应于上述期限届满前向乙方提出更正，否则视为接受乙方所标注的运费基价以及服务费用。</div>
                <div>（3）甲方应在运费以及服务费用支付期限届满之前，将运费、服务费用支付至乙方指定的账户。对于逾期支付的款项，甲方应按每日万分之五的比例向乙方支付违约金。</div>
                <div>（4）港杂费等其他费用，甲方应在收到乙方转来的账单后五个工作日之内支付至乙方指定的账户。对于逾期支付的款项，甲方应按每日万分之五的比例向乙方支付违约金。</div>
                <div>（5）对于预付运费条款下的出口货物，在甲方未结清所有费用之前，乙方也有权滞留相应的运输单据，由此产生的所有损失和责任由甲方承担。甲乙双方对此另有约定的，从其约定。
                <div>第四条 违约责任</div>
                <div>1、甲方未按照乙方要求结算相应费用的，应承担逾期付款责任，具体以本协议第三条约定为准。</div>
               <div> 2、当事人违反协议应向对方支付违约金，违约金的数额由当事人商定。当事人违反协议造成对方经济损失的，应依法承担赔偿责任；</div>
                <div>第五条 免责声明</div>
               <div> 如货物的灭失或者损坏是由于《海商法》第五十一条所列明的以及其他非乙方过失或者故意造成的，乙方不承担任何责任。</div>
               <div> 第六条 管辖与法律适用</div>
               <div> 1、本合同项下发生的任何纠纷或者争议，应向乙方所在地有管辖权的法院提起诉讼。</div>
               <div> 2、本合同的订立、效力、解释、履行、争议的解决均适用中华人民共和国法律。</div>
               <div> 第七条 其他</div>
               <div> 1、本协议未尽事宜，由双方另行协商，签订补充协议。</div>
                <div>2、本协议生效后，除遇第八条原因外将长期有效。本协议正本一式两份，双方各执一份，以昭信守。</div>
                </div>
            </div>
        </el-dialog>
        <footerbottom></footerbottom>
    </div>
   
</template>
<script>
import headerOrder from './../common/header.vue'
import footerbottom from './../common/footer.vue'
import { Loading } from 'element-ui';
export default {
    components:{headerOrder,footerbottom},
    data(){
        return{
            blShow:false,
            shShow:false,
            fcShow:false,
            border:false,
            value1:false,
            dialogVisible2:false,
            params:{
                dcUserMail:'',
                dcUserPhone:'',
                goodName:'',
                dcUserCompanyName:'',
                cabinetNum:'1',
                dcUserName:''
            },
            dialogVisible:false,
            imgfileList:[],
            userInfo:{},
            fileList:[],
            hxInfo:{},
            cnyTotal:0,
            usdTotal:0,
            skuId:0,
            freightVoSkuList:[],
            checked:false,
            
            ruleForm:{
                weCompanyName:'',
                weContacts:'',
                wePhone:'',
                weProductWeight:'',
                weNum:1,
                weProductName:'',
            },
            rules: {
                 weCompanyName: [
                    { required: true, message: '请输入公司名称', trigger: 'blur', }
                ],
                 weContacts: [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                 wePhone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' }
                ],
                 weProductName: [
                    { required: true, message: '请输入品名', trigger: 'blur' }
                ],
                 weProductWeight: [
                    { required: true, message: '请输入预估货重', trigger: 'blur' }
                ],
                 weNum: [
                    { required: true, message: '请输预定柜量', trigger: 'blur' }
                ],
            }
        }
    },
    created(){
        this.skuId = Number(this.$route.query.id)
    },
    mounted(){
        this.getData()
        this.getHistoryDcUser()
        // this.skuId = this.
        let token = localStorage.getItem('token')
		if(!token || token == 'null' || token == '' || token == 'undefined'){
			// next('/login')
		
		}else{
			this.token = token
            let userInfo = JSON.parse(localStorage.getItem('userInfo')).user
            this.userInfo = userInfo
            console.log(this.userInfo)
            // this.phone = JSON.parse(userInfo).user.phone
            // this.getUserInfo(userInfo.userId)
            // this.
		}
        this.skuId = Number(this.$route.query.id)
        // debugger
        this.freightVoSkuList = JSON.parse(this.$route.query.freightVoSkuList)
    },
    methods:{
        gouser(){
          this.$router.push('/user?type=1')  
        },
        getHistoryDcUser(){
            this.$get('/freight/getHistoryDcUser',).then(res =>{

            })
        },
        handleClose(){
            this.dialogVisible = false
        },     // freight/getHistoryDcUser
        handleClose1(){
            // debugger
            this.dialogVisible2 = false
        },
        tijiao(){
             console.log(this.params )
            
            //    :disabled="userInfo.bizStatus != 'checked'"
            if(this.hxInfo.stockNum < this.params.cabinetNum){
                this.$message.error('您所填写的柜量超过总库存数量')
                return false
            }
               if(this.userInfo.bizStatus != 'checked'){
                   return false
               }  
            if(this.params.dcUserCompanyName == ''){
                this.$message.error('请输入公司名称')
                return false
            }
             if(this.params.dcUserName == ''){
                this.$message.error('请输入托运联系人姓名')
                return false
            }
            if(this.params.dcUserMail == ''){
                this.$message.error('请输入托运联系人邮箱')
                return false  
            }
            //  if(this.params.blUserName == ''){
            //     this.$message.error('请输入托运联系人姓名')
            //     return false
            // }
            if(this.params.goodName == ''){
                 this.$message.error('请输入品名')
                return false
            }
            if(this.checked == false){
                this.$message.error('请同意订舱协议')
                return false
            } else {
                this.dialogVisible = true
            }
        },
        jisuancabinetNum(){
            console.log('111')
            if(this.hxInfo.surchargeAmountDetail){
                this.cnyTotal = 0
                this.usdTotal = 0
                
                this.hxInfo.surchargeAmountDetail.forEach(ele => {
                    // debugger
                    if(ele.totalFlag){
                        // ele.fee = ele.fee
                        ele.totalfee  = ele.fee * this.params.weNum
                    } else {
                        ele.totalfee  = ele.fee
                    }
                    if(ele.feeType == "CNY"){
                        // this.cnyTotal += ele.fee
                        this.cnyTotal += ele.totalfee
                    }
                    if(ele.feeType == 'USD'){
                        //  this.usdTotal += ele.fee
                         this.usdTotal += ele.totalfee
                    }
                });
            }
            console.log(this.hxInfo)
        },
        subOrder(formName){
            //  this.$refs.ruleForm.validate((valid) =>{
            //      console.log(valid)
            //     if(valid){
                    
            //     }
            // })
            console.log(this.$refs.ruleForm)
            // if
            
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = this.ruleForm
                    // this.imgfileList[0]
                     params.freightId=this.skuId
                     if(this.imgfileList.length != 0){
                        params.weAnnexUrl= this.imgfileList[0]
                     }
                     this.$post('/workOrder/weOrder',params).then(res =>{
                             if(res.code == '200'){
                                  this.$router.push("./ordertip?orderId="+ res.data)
                             }
                     })
                    // alert('submit!');
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
            if(this.checked == false){
                this.$message.error('请先同意订舱协议')
                return false
            }

            //    this.$refs[formName].validate((valid) => {
            //     if (valid) {
            //         alert('submit!');
            //     } else {
            //         console.log('error submit!!');
            //         return false;
            //     }
            //     });
        },
        submitFreightOrder(){
            // let params = this.hxInfo
            // params.dcUserName
            // Loading.service(options);
            // return false
           let loading = this.$loading({
                lock: true,
                text: '提交中',
                spinner: 'el-icon-loading',
            // background: 'rgba(0, 0, 0, 0.7)'
            });
           
            let params =  Object.assign(this.hxInfo,this.params)
            params.depositAmount = this.hxInfo.cabinetAmount * this.params.cabinetNum 
            // debugger
            params.freightId = this.hxInfo.id
            // return false
             params.extra1 = this.imgfileList[0]
            // if(this.imgfileList.length == 1){
                 params.extra2 =  this.imgfileList[1]
            // }
            // if(this.imgfileList.length == 2){
                 params.extra3 =  this.imgfileList[2]
            // }
            params.surchargeAmountDetail = JSON.stringify(this.hxInfo.surchargeAmountDetail)
            console.log(this.imgfileList)
            // return false
            this.$post('/freight/submitFreightOrder',params).then(res =>{
                // loadingInstance.close();
                loading.close();
                if(res.code != '200'){
                    return false
                }
                this.$router.push("./ordertip?orderId="+ res.data)
                return
                if(params.depositEnable == 0){
                    // alert('1')
                    //  this.$router.push('/successOrder?orderId=' + res.data)
                      this.$router.push('/successOrder?orderId='+ res.data)
                } else {
                    //  alert('2')
                    this.$router.push('/payAmount?orderId=' + res.data)
                }
                // if(params.depositEnable == 0){
                //     // alert('1')
                //     //  this.$router.push('/successOrder?orderId=' + res.data)
                //       this.$router.push('/successOrder?orderId='+ res.data)
                // } else {
                //     //  alert('2')
                //     this.$router.push('/payAmount?orderId=' + res.data)
                // }
                // console.log(this.hxInfo.depositAmount !== 0)
                // if(this.hxInfo.depositAmount != 0 ){
                //     // alert('1')
                //      this.$router.push('/successOrder?orderId=' + res.data)
                  
                    
                // } else {
                //     //  alert('2')
                //      this.$router.push('/payAmount?orderId=' + res.data)
                // }
            })
        },
        getData(){
            // debugger
            this.$post('/freight/searchFreight4app',{id:this.skuId}).then(res =>{
                    res.data.list[0].closeFileTime1 =res.data.list[0].closeFileTime.substr(0, 13) + '时'
                //    alert(ele.closeFileTime)
                  res.data.list[0].closePassTime1 =res.data.list[0].closePassTime.substr(0, 13)+ '时'
                  res.data.list[0].sailTime1 = res.data.list[0].sailTime.substr(0, 13)+ '时'
                  
               this.hxInfo = res.data.list[0]
            //    this.jisuancabinetNum()
                
               if(res.data.list[0].surchargeAmountDetail){
                    this.hxInfo.surchargeAmountDetail = JSON.parse(res.data.list[0].surchargeAmountDetail)

                    if(this.hxInfo.surchargeAmountDetail){
                    this.hxInfo.surchargeAmountDetail.forEach(ele => {
                        if(ele.totalFlag){
                            ele.fee  = ele.fee * this.params.weNum
                        }
                        if(ele.feeType == "CNY"){
                            this.cnyTotal += ele.fee
                        }
                        if(ele.feeType == 'USD'){
                            this.usdTotal += ele.fee
                        }
                    });
            }
            this.jisuancabinetNum()
               } else {
                    this.hxInfo.surchargeAmountDetail = []
               }
              
            })
        },
        hudeshow(type){
            if(type == 'fcShow'){
                this.fcShow = false
            } 
            if(type == 'blShow'){
                this.blShow = false
            }
            if(type == 'shShow'){
                this.shShow = false
            }
        },
        deltfile(index){
            console.log(index)
            this.fileList.splice(index,1)
            this.imgfileList.splice(index,1)
            // let fileList = this.fileList
            // this.fileList = fileList.splice(index,1)
            // console.log(this.fileList)
            // this.imgfileList = this.imgfileList.splice(index,1)
        },
         handleSuccess(res, file, fileList){
                if(res.code == '200'){
                    this.fileList = fileList
                    this.imgfileList.push(res.data)
                    console.log(this.imgfileList)
                    this.$Notice.success({
                        title: "上传成功",
                    });
                    // this.initData()
                }
                this.fullscreenLoading = false
                // this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                //     loadingInstance.close();
                // });
            },
    }
}
</script>
<style lang="scss" scoped>
.main{
    margin-top: 40px;
    display: flex;
    // position: absolute;
    top: 20px;
    margin: 0 auto;
    position: relative;
    z-index: 9;
    // width: ;
}
.mainLeft{
    width: 739px;
    // margin-left: 160px;
    background: #FFFFFF;
box-shadow: 0px 4px 20px 1px rgba(0, 186, 253, 0.2);
border-radius: 6px 6px 6px 6px;
padding-bottom: 35px;

    // height: ;
}
.headerBack{
    background: linear-gradient(180deg, #73DAFF 0%, rgba(115, 218, 255, 0) 100%);
border-radius: 0px 0px 0px 0px;
opacity: 1;
height: 127px;
width: 100%;
position: absolute;
top: 0;
}
.mainRight{
    width: 430px;
    margin-left: 20px;
}
.mainbox{
    margin: 34px 40px 0 33px;
    background: #fff;
}
.title{
    border-bottom: 1px rgba(196, 196, 196, 1) dashed;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 19px;
font-family: Roboto-Regular, Roboto;
font-weight: 400;
color: #464646;
}
.xinxi{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.guankou{
    display: flex;
    align-items: center;
    height: 102px;
    border-bottom: 1px solid #f5f5f5;
}
.stong{
    font-weight: 500;
    margin-top: 5px;
    font-size:20px
}
.title1{
    justify-content: flex-start;
    align-items: center;
}
.buttonadd{
    margin-top: 20px;
    width: 316px;
    height: 52px;
    // background:rgba(22, 119, 255, 0.1);
    border-radius: 4px;
    // opacity: 0.1;
    line-height: 52px;
    display: flex;
    align-items: center;
   justify-content: center;
   cursor: pointer;
    color: #1677FF;
}
.buttonadd img{
    width: 20px;
    height: 20px;
}
.close{
    cursor: pointer;
}
.close img{
    width: 30px;
    height: 30px;
}
.fs{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.strong{
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
}
.xiangxing{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    font-weight: 500;
}
.upload{
    margin-top: 32px;
}
::v-deep .el-upload-dragger{
    width: 320px;
    height: 140px;
}
.fileListt{
    margin-left: 20px;
}
.fileListt div:nth-child(1){
    margin-top: 0 !important;
}
.filebox{
   width: 272px;
    height: 30px;
    background: #F5F5F5;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    
}

.closefile{cursor: pointer;}
.rightbox{
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    background: #fff;
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
box-shadow: 0px 4px 20px 1px rgba(0, 186, 253, 0.2);
border-radius: 6px 6px 6px 6px;
}
.rightTitle{
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 800;

    padding-bottom: 15px;
    // opacity: 0.5;
   
border-bottom: 1px solid #DADADA;;
    display: flex;
    justify-content: space-between;
    align-items: center;
//  height: 18px;
 
}
.boc{
    margin-top: 20px;
    display: flex;
    // margin-left: 60px;
    justify-content: space-between;
    margin-right: 20px;
}
.shuju{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    color: rgba(204, 204, 204, 1);
}
.shuju img{
    width: 84px;
    height: 66px;

}
.link{
    color: #1677FF !important;
    cursor: pointer;
    // font-size: 20px !important;
}
.tijiao{
    width: 100%;
    height: 53px;
    background: rgba(0, 186, 253, 1);
    border-radius: 4px;
    line-height: 53px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    margin-top: 32px;
}
.dialogtitle{
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
::v-deep .el-dialog__footer{
    text-align: center !important;
}
.fjiaf{
    margin-top: 10px;
    font-size: 12px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #639AF8;
cursor: pointer;
}
.dcxie{
    margin-left:39px;
    margin-top: 35px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    // text-align: center;
    color: #666666 !important;
    line-height: 18px;
}
.dcxie i{
    color: rgba(22, 119, 255, 1);
}
::v-deep .el-checkbox__label {
    color:  #666 !important;
}
.xiyititle{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin-bottom: 10px;
}
.van-cell{
    border-bottom: 1px solid #f5f5f5;
}
.boc div{
    width: 100px;
}
.title i{
    font-size: 14px;
    color: #ee0a24;
   
}
.contentMain{
   background: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    width: 100%;
    // min-height: 2000px;
    display: flex;
    align-items: center;
    // justify-content: center;
    // background: linear-gradient(180deg, #73DAFF 0%, rgba(115, 218, 255, 0) 100%) no-repeat;
    background-size: 100% 127px;
    flex-direction: column;
    position: relative;
    padding-bottom: 40px;
}
::v-deep .el-upload-dragger{
    // width: 664px;
    height: 131px;
}
::v-deep .el-icon-upload{
    margin-top: 20px;
}
.smallTitle{
    font-size: 12px;
    color: rgba(172, 172, 172, 1);
}
::v-deep .el-checkbox{
    font-size: 18px !important;
}
::v-deep .el-checkbox__inner{
    width: 18px !important;
    height: 18px !important;
}
::v-deep .el-checkbox__inner::after{
    height: 9px !important;
    left: 5px !important;
}
.rightText{
    margin-left: 12px;
}
.rightText >div{
    margin-top: 10px;

font-size: 14px;

color: #464646;
}
::v-deep .el-input{
    height: 40px;
    background: rgba(244, 244, 244, 1) !important;
}
</style>